:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;
    --vpInitialHeight: 100vh;
    
    // ***********************************
    // *****  theming css variables  *****
    // ***********************************

    // basic
    --theme--background-color: #{$brand--white};
    --theme--background: #{$brand--white};
    background: var(--theme--background);
    @include on-light-theme-vars;
    
    // ******************************
    // *****  layout variables  *****
    // ******************************


    --bs-gutter-x: 0.75rem;
    @include media-breakpoint-up(sm) {
        --bs-gutter-x: 30px;
    }

    --header--height: #{$header--height--mobile};
    --top-utility-bar--height: #{$top-utility-bar--height--mobile};
    --promo-bar--height: 0px;

    --featured-link--line-offset: 2px;

    @include media-breakpoint-up(lg) {
        --header--height: #{$header--height--desktop};
        --top-utility-bar--height: #{$top-utility-bar--height--desktop};
    }
}

// focus - modern browsers support for focus by different input methods - older browsers ignore entire rule
@supports(a:focus-visible) {
    :focus:not(:focus-visible) {
        outline: 0;
    }
    .btn:focus:not(:focus-visible) {
        box-shadow: none;
    }
}
@supports not (a:focus-visible) {
    body:not(.keyboard-control) {
        a:focus {
            outline: 0;
        }
        .btn:focus {
            box-shadow: none;
        }
    }
}

body.site-layout {

    overscroll-behavior-y: none;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: calc(var(--top-utility-bar--height) + var(--header--height));

    #main-content {
        flex: 1 1 auto;
    }

    #main-footer {
        flex: 0 0 auto;
    }

    background: var(--theme--background-color);
    color: var(--theme--color);

    .page-edge-spacing {
        padding-left: var(--page-edge-spacing);
        padding-right: var(--page-edge-spacing);
    }

}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration--color);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
}

// rules

hr {
    border-color: var(--theme--rule--color);
}

// emergency message

.emergency-message {
    position: fixed !important;
    z-index: 9 !important;
    top: calc(var(--top-utility-bar--height) + var(--header--height));
    left: 0;
    width: 100%;
    border: 0;
    max-height: calc(90vh - (var(--top-utility-bar--height) + var(--header--height)));
    overflow-y: auto;
    transition: transform 300ms ease-in-out;
    will-change: transform;
    .fa-exclamation-triangle {
        color: var(--theme--decor--color);
    }
    .header-hidden:not(.mobile-menu-active) & {
        transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height))),0);
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// promo bar

body.with-promo-bar {
    --promo-bar--height: 40px;
    --promo-bar--bg-color: #{$brand--dark-grey};
    .promo-bar {
        position: fixed;
        top: calc(var(--top-utility-bar--height) + var(--header--height));
        left: 0;
        width: 100%;
        height: var(--promo-bar--height);
        z-index: 8;
        border: 0;
        background-color: var(--promo-bar--bg-color);
        will-change: transform;
        transition: transform 300ms ease-in-out, background-color 300ms linear;
        & > div {
            font-size: 1rem;
            height: 100%;
            ul {
                height: 100%;
            }
        }
    }
    &.header-hidden:not(.mobile-menu-active) .promo-bar {
        transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height))),0);
    }
    &.with-hero {
        --promo-bar--bg-color: #{rgba($brand--dark-grey, 0.8)};
        --promo-bar--scrolled--bg-color: #{$brand--dark-grey};
        .promo-bar {
            backdrop-filter: blur(10px);
        }
        &.page-scrolled .promo-bar {
            background-color: var(--promo-bar--scrolled--bg-color);
            transition-delay: 0s, 300ms;
        }
    }
}

// page title area

.page-title-area {
    .hero + & {
        margin-top: calc(-0.5 * var(--header--height));
        @include media-breakpoint-up(sm) {
            margin-top: calc(-0.75 * var(--header--height));
        }
        @include media-breakpoint-up(md) {
            margin-top: calc(-1 * var(--header--height));
        }
    }
    .breadcrumb {
        background-color: transparent;
        justify-content: center;
        margin-bottom: 0;
        font-size: 1rem;
        text-transform: uppercase;
        .breadcrumb-item {
            &:not(:first-child):before {
                content: '';
                display: inline-block;
                vertical-align: baseline;
                height: 100%;
                width: 1em;
                background: no-repeat center url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%23ffffff' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
                background-size: auto 80%;
                background-position: left 0.1em;
                opacity: 0.4;
            }
            a {
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
    h1 {
        text-align: center;
    }
    &.page-title-area--overlap {
        margin-bottom: 0 !important;
        .custom-panel {
            padding-bottom: 3rem !important;
        }
    }
}

.info-bar--page-title-area--overlap {
    position: relative;
    z-index: 2;
    margin-top: -2rem !important;
}

// full-width--breakout (make content that has to be inside a column but needs to be full width)

.full-width-breakout {
    display: block;
    width: 100%;
    &--inner {
        display: block;
        position: relative;
        width: var(--vpWidth);
        margin: 0 0 0 calc((var(--vpWidth) - 100%) * -.5) !important;
    }
}

// custom backgrounds

.bg--transparent {
    background-color: transparent !important;
}

// edge-illustrations

.edge-illustrations {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .edge-illustrations--content {
        width: 100%;
    }
    .edge-illustrations--left, .edge-illustrations--right {
        width: 50vw;
        img {
            width: 100%;
            height: 50vw;
            object-fit: contain;
            @include media-breakpoint-up(sm) {
                height: 40vw;
            }
            @include media-breakpoint-up(md) {
                height: 30vw;
            }
        }
    }
    .edge-illustrations--left img {
        object-position: 0 0;
    }
    .edge-illustrations--right img {
        object-position: 100% 0;
    }
    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        .edge-illustrations--content {
            flex: 1 1 auto;
            order: 2;
        }
        .edge-illustrations--left, .edge-illustrations--right {
            width: 300px;
            flex: 0 0 auto;
            img {
                height: 300px;
            }
        }
        .edge-illustrations--left {
            order: 1;
        }
        .edge-illustrations--right {
            order: 3;
        }
    }
    @include media-breakpoint-up(xl) {
        .edge-illustrations--left, .edge-illustrations--right {
            width: 400px;
            img {
                height: 400px;
            }
        }
    }
}

// spaced 3 column layout

.spaced-three-col-layout {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    .spaced-three-col-layout--item {
        width: 100%;
        max-width: 250px;
        .custom-icon {
            margin-right: 0.2em;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(md) {
        gap: 30px 60px;
        .spaced-three-col-layout--item {
            max-width: 300px;
        }
    }
    @include media-breakpoint-up(lg) {
        gap: 30px calc(50% - 375px);
        .spaced-three-col-layout--item {
            max-width: 250px;
        }
    }
    @include media-breakpoint-up(xl) {
        gap: 30px calc(50% - 450px);
        .spaced-three-col-layout--item {
            max-width: 300px;
        }
    }
}

// 50/50 layout with optional carousel

.split-img-content-layout {
    display: flex;
    flex-direction: column;
    .split-img-content-layout--img {
        background-color: var(--dark);
        width: 100%;
    }
    .split-img-content-layout--content {
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;
        .split-img-content-layout--img {
            position: relative;
            width: 50%;
            min-height: 100%;
            overflow: hidden;
            &:before { /* used to set the minimum section height to 4:3 image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 75%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: calc(100% + 1px);
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .carousel-inner, .carousel-item {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
        .split-img-content-layout--content {
            display: flex;
            width: 50%;
            min-height: 100%;
            align-items: center;
            .split-img-content-layout--content--inner {
                width: 100%;
                .container { // set max-widths to align with other page elements
                    margin-left: #{$split-img-content--content--margin--lg};
                    margin-right: auto;
                    max-width: calc((#{map-get($container-max-widths, lg)} / 2) - #{$split-img-content--content--margin--lg});
                }
            }
        }

        &.split-img-content-layout--img-right {
            flex-direction: row-reverse;
            .split-img-content-layout--content {
                .container { // set max-widths to align with other page elements
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--lg};
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xl};
                    max-width: calc((#{map-get($container-max-widths, xl)} / 2) - #{$split-img-content--content--margin--xl});
                }
            }
        }
        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xl};
                }
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xxl};
                    max-width: calc((#{map-get($container-max-widths, xxl)} / 2) - #{$split-img-content--content--margin--xxl});
                }
            }
        }
        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xxl};
                }
            }
        }
    }
}
