@keyframes heart-rate--section {
    from { transform: scale(0.5); }
    to { transform: scale(1); }
}
@keyframes heart-rate--pulse {
    from { transform: translate3d(-180px,0,0); }
    to { transform: translate3d(180px,0,0); }
}

.heart-rate {
    height: auto;
    overflow: hidden;

    .roundel & {
        margin: 4%;
    }

    .section {
        transform-origin: center center;
        transform: scale(0.5);
    }

    .section-1 {fill: #0068ae; }
    .section-2 {fill: #0099ad; }
    .section-3 {fill: #00ad0c; }
    .section-4 {fill: #a3ba1e; }
    .section-5 {fill: #cee545; }
    .section-6 {fill: #edd943; }
    .section-7 {fill: #efb94b; }
    .section-8 {fill: #f39200; }
    .section-9 {fill: #f50; }
    .section-10 {fill: #e71d73; }
    .section-11 {fill: #a3265f; }
    .section-12 {fill: #71132a; }

    .outer-ring {fill: #ececec;}
    .inner {fill: #333;}

    .g--pulse { transform-origin: center center; }
    .pulse--min { stop-color: rgba(white, 0.01); }
    .pulse--max { stop-color: white; }
    .pulse {
        transform: translate3d(-180px,0,0);
        will-change: transform;
        animation: heart-rate--pulse;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &.heart-rate--mono {
        .section {fill: white !important; }
        .outer-ring {fill: transparent; }
    }

    &:not(.hunt-watch), &.hunt-watch.hunt-in {

        &.heart-rate-1 {
            .section-1, .section-2, .section-3 {
                animation: heart-rate--section 0.5s ease-out;
                animation-fill-mode: forwards;
            }
            .section-2 {animation-delay: 0.1s; }
            .section-3 {animation-delay: 0.2s; }
            .g--pulse {transform: scaleY(0.25);}
            .pulse {
                animation-duration: 2s;
            }
        }
        &.heart-rate-2 {
            .section-1, .section-2, .section-3, .section-4, .section-5, .section-6 {
                animation: heart-rate--section 0.5s ease-out;
                animation-fill-mode: forwards;
            }
            .section-2 {animation-delay: 0.1s; }
            .section-3 {animation-delay: 0.2s; }
            .section-4 {animation-delay: 0.3s; }
            .section-5 {animation-delay: 0.4s; }
            .section-6 {animation-delay: 0.5s; }
            .g--pulse {transform: scaleY(0.5);}
            .pulse {
                animation-duration: 1.6s;
            }
        }
        &.heart-rate-3 {
            .section-1, .section-2, .section-3, .section-4, .section-5, .section-6, .section-7, .section-8, .section-9 {
                animation: heart-rate--section 0.5s ease-out;
                animation-fill-mode: forwards;
            }
            .section-2 {animation-delay: 0.1s; }
            .section-3 {animation-delay: 0.2s; }
            .section-4 {animation-delay: 0.3s; }
            .section-5 {animation-delay: 0.4s; }
            .section-6 {animation-delay: 0.5s; }
            .section-7 {animation-delay: 0.6s; }
            .section-8 {animation-delay: 0.7s; }
            .section-9 {animation-delay: 0.8s; }
            .g--pulse {transform: scaleY(0.75);}
            .pulse {
                animation-duration: 1.2s;
            }
        }
        &.heart-rate-4 {
            // all sections
            .section {
                animation: heart-rate--section 0.5s ease-out;
                animation-fill-mode: forwards;
            }
            .section-2 {animation-delay: 0.1s; }
            .section-3 {animation-delay: 0.2s; }
            .section-4 {animation-delay: 0.3s; }
            .section-5 {animation-delay: 0.4s; }
            .section-6 {animation-delay: 0.5s; }
            .section-7 {animation-delay: 0.6s; }
            .section-8 {animation-delay: 0.7s; }
            .section-9 {animation-delay: 0.8s; }
            .section-10 {animation-delay: 0.9s; }
            .section-11 {animation-delay: 1s; }
            .section-12 {animation-delay: 1.1s; }
        }

    }

}
