:root {
    // use 'rough-1' as default settings
    --top-mask--src: url('../../img/masks/rough-top-edge-mask.svg');
    --top-mask--size-x: 800px;
    --top-mask--size-y: 10px;
    --top-mask--position-x: center;
    --top-mask--position-y: top;
    --top-mask--repeat-x: repeat-x;
    --bottom-mask--src: url('../../img/masks/rough-bottom-edge-mask.svg');
    --bottom-mask--size-x: 800px;
    --bottom-mask--size-y: 10px;
    --bottom-mask--position-x: center;
    --bottom-mask--position-y: bottom;
    --bottom-mask--repeat-x: repeat-x;
}

.section-mask--top-edge, .section-mask--bottom-edge {
    background: transparent !important;
    position: relative;
    z-index: 2;
    &:before {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--theme--background);
        mask-mode: alpha;
        will-change: transform;
    }
}

.section-mask--top-edge:not(.section-mask--bottom-edge) {
    &:before {
        mask-image: var(--top-mask--src), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=);
        mask-position: var(--top-mask--position-x) var(--top-mask--position-y), left bottom;
        mask-size: var(--top-mask--size-x) var(--top-mask--size-y), 100% calc(100% - (var(--top-mask--size-y) - 1px));
        mask-repeat: var(--top-mask--repeat-x), no-repeat;
    }
}
.section-mask--bottom-edge:not(.section-mask--top-edge) {
    &:before {
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=), var(--bottom-mask--src);
        mask-position: left top, var(--bottom-mask--position-x) var(--bottom-mask--position-y);
        mask-size: 100% calc(100% - (var(--bottom-mask--size-y) - 1px)), var(--bottom-mask--size-x) var(--bottom-mask--size-y);
        mask-repeat: no-repeat, var(--bottom-mask--repeat-x);
    }
}
.section-mask--top-edge.section-mask--bottom-edge {
    &:before {
        mask-image: var(--top-mask--src), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=), var(--bottom-mask--src);;
        mask-position: var(--top-mask--position-x) var(--top-mask--position-y), 0 calc(var(--top-mask--size-y) - 1px), var(--bottom-mask--position-x) var(--bottom-mask--position-y);
        mask-size: var(--top-mask--size-x) var(--top-mask--size-y), 100% calc(100% - (var(--top-mask--size-y) + var(--bottom-mask--size-y) - 2px)), var(--bottom-mask--size-x) var(--bottom-mask--size-y);
        mask-repeat: var(--top-mask--repeat-x), no-repeat, var(--bottom-mask--repeat-x);
    }
}

// mask styles

.section-mask--rough-1 {
    --top-mask--src: url('../../img/masks/rough-top-edge-mask.svg');
    --top-mask--size-x: 800px;
    --top-mask--size-y: 10px;
    --top-mask--position-x: center;
    --top-mask--position-y: top;
    --top-mask--repeat-x: repeat-x;
    --bottom-mask--src: url('../../img/masks/rough-bottom-edge-mask.svg');
    --bottom-mask--size-x: 800px;
    --bottom-mask--size-y: 10px;
    --bottom-mask--position-x: center;
    --bottom-mask--position-y: bottom;
    --bottom-mask--repeat-x: repeat-x;
}

.section-mask--rough-2 {
    --top-mask--src: url('../../img/masks/rough-two-layer-top-edge-mask.svg');
    --top-mask--size-x: max(1200px,100%);
    --top-mask--size-y: 50px;
    --top-mask--position-x: center;
    --top-mask--position-y: top;
    --top-mask--repeat-x: no-repeat;
    --bottom-mask--src: url('../../img/masks/rough-two-layer-bottom-edge-mask.svg');
    --bottom-mask--size-x: max(1200px,100%);
    --bottom-mask--size-y: 50px;
    --bottom-mask--position-x: center;
    --bottom-mask--position-y: bottom;
    --bottom-mask--repeat-x: no-repeat;
}

.section-mask--brush-1 {
    --top-mask--src: url('../../img/masks/brush-top-edge-mask.svg');
    --top-mask--size-x: max(1200px,100%);
    --top-mask--size-y: 60px;
    --top-mask--position-x: center;
    --top-mask--position-y: top;
    --top-mask--repeat-x: no-repeat;
    --bottom-mask--src: url('../../img/masks/brush-bottom-edge-mask.svg');
    --bottom-mask--size-x: max(1200px,100%);
    --bottom-mask--size-y: 120px;
    --bottom-mask--position-x: center;
    --bottom-mask--position-y: bottom;
    --bottom-mask--repeat-x: no-repeat;
}

.section-mask--brush-2 {
    --top-mask--src: url('../../img/masks/brush-top-edge-mask-2.svg');
    --top-mask--size-x: max(1200px,100%);
    --top-mask--size-y: 80px;
    --top-mask--position-x: center;
    --top-mask--position-y: top;
    --top-mask--repeat-x: no-repeat;
    --bottom-mask--src: url('../../img/masks/brush-bottom-edge-mask-2.svg');
    --bottom-mask--size-x: max(1200px,100%);
    --bottom-mask--size-y: 100px;
    --bottom-mask--position-x: center;
    --bottom-mask--position-y: bottom;
    --bottom-mask--repeat-x: no-repeat;
}

// split layout masking

.split-img-content-layout.split-img-content-layout--masked {
    .split-img-content-layout--img > picture > img, .split-img-content-layout--img .carousel-inner {
        mask-image: url(../../img/masks/brush-double-mask-1.svg);
        mask-position: top right;
        mask-size: 150% 100%;
        mask-repeat: no-repeat;
        @include media-breakpoint-up(sm) {
            mask-size: 140% 100%;
        }
        @include media-breakpoint-up(md) {
            mask-size: 130% 100%;
        }
        @include media-breakpoint-up(lg) {
            mask-size: 120% 100%;
        }
        @include media-breakpoint-up(xl) {
            mask-size: 110% 100%;
        }
        @include media-breakpoint-up(xxl) {
            mask-size: 100% 100%;
        }
    }
    .carousel-indicators {
        margin-bottom: 2rem;
    }
    &.split-img-content-layout--img-right {
        .split-img-content-layout--img > picture > img, .split-img-content-layout--img .carousel-inner {
            mask-position: top left;
        }
    }
}
