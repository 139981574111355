#main-footer {
    footer {

        #main-footer--social-links {
            --theme--link--color: #{$brand--white};
            --theme--link--color--hover: #{$brand--orange};
            transition: color 250ms linear;
            svg {
                font-size: 2rem;
            }
        }

        #main-footer--escapade-section {
            text-align: center;
            img {
                width: 100vw;
                height: auto;
                max-width: 160px;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                li {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                    &:not(:first-child):before {
                        display: block;
                        content: '';
                        width: 100px;
                        height: 1px;
                        background-color: rgba(white,0.4);
                    }
                }
            }
            @include media-breakpoint-up(md) {
                img {
                    max-width: 200px;
                }
                ul {
                    flex-direction: row;
                    justify-content: center;
                    gap: 30px;
                    li {
                        flex-direction: row;
                        gap: 30px;
                        &:not(:first-child):before {
                            width: 1px;
                            height: 60px;
                        }
                    }
                }
            }
        }

    }
}
