﻿#header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 9000;
    pointer-events: none;
    header {
        position: relative;
        height: calc(var(--top-utility-bar--height) + var(--header--height));
        transition: transform 300ms ease-in-out, box-shadow 300ms linear;
        transition-delay: 0s, 0s;
        filter: drop-shadow(0 0 3px transparent);
        will-change: transform, filter;
        &:before {
            opacity: 0.9;
            transition: opacity 300ms linear;
            transition-delay: 500ms;
            will-change: transform, opacity;
        }
        .header-hidden:not(.mobile-menu-active) & {
            transform: translate3d(0,-100%,0);
        }
        .page-scrolled & {
            transition-delay: 0ms, 500ms;
            filter: drop-shadow(0 0 3px rgba(black, 0.2));
            &:before {
                transition-delay: 0s;
                opacity: 1;
            }
        }

        #skip-nav {
            display: inline-block;
            position: absolute;
            top: 46px;
            left: 15px;
            color: $primary;
            font-size: 16px;
            text-transform: uppercase;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;
            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .header-utility-bar {
            position: relative;
            z-index: 2;
            --theme--link--color: white;
            --theme--link--color--hover: white;
            height: var(--top-utility-bar--height);
            font-size: 16px;
            text-transform: uppercase;
            pointer-events: all;
            .dropdown {
                font-family: $font-family-base;
                text-transform: none;
                .btn-link {
                    color: var(--theme--link--color);
                    text-transform: uppercase;
                    font-size: 16px;
                    &:hover, &:focus {
                        color: var(--theme--link--color--hover);
                    }
                }
                .dropdown-item {
                    padding: .5rem 1.5rem;
                }
                .dropdown-header {
                    font-size: 1rem;
                    color: $brand--orange;
                }
            }
            &--inner {
                height: var(--top-utility-bar--height);
            }
            &--basket {
                display: block;
                flex: 0 1 auto;
                position: relative;
                cursor: pointer;
                i, svg {
                    font-size: 1rem;
                }
                .basket-badge {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    background-color: $brand--orange;;
                    color: $brand--white;
                    font-size: 12px;
                    font-weight: 700;
                    padding: 2px 2px 1px;
                    min-height: 19px;
                    min-width: 19px;
                    border-radius: 10px;
                    text-decoration: none;
                    transform: translateY(-5px);
                }
            }
        }

        .header-content {
            display: flex;
            width: 100%;
            height: var(--header--height);
            justify-content: flex-end;
            align-items: center;
            pointer-events: all;
            .btn-text {
                position: relative;
                color: var(--theme--color);
                .svg-inline--fa {
                    vertical-align: middle;
                }
            }
            .header--logo {
                flex: 0 1 auto;
                width: 100%;
                max-width: 150px;
                a {
                    display: block;
                    width: 100%;
                    img {
                        width: 100%;
                        height: auto;
                        transform: translate3d(0,-4%,0);
                    }
                }
            }
            .header--cta {
                flex: 0 0 auto;
            }
            .header--desktop-search-toggle {
                display: none;
            }
            .header--mobile-menu-toggle {
                flex: 0 0 auto;
                button {
                    .bars {
                        display: block;
                        width: 21px;
                        height: 21px;
                        position: relative;
                        transition: transform 200ms ease-in-out;
                        will-change: transform;
                        i {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 21px;
                            height: 2px;
                            border-radius: 1px;
                            background-color: var(--theme--color);
                            transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                            will-change: transform;
                            &:nth-child(1) {
                                transform-origin: left top;
                            }
                            &:nth-child(2) {
                                transform-origin: center center;
                                top: 10px;
                            }
                            &:nth-child(3) {
                                transform-origin: left bottom;
                                top: 16px;
                            }
                        }
                    }
                
                    &[aria-expanded="true"] {
                        .bars {
                            transform: rotate(-90deg) translate3d(0,-0.5px,0);
                            i {
                                &:nth-child(1) {
                                    transform: translate3d(4px,-1px,0) rotateZ(45deg);
                                }
                                &:nth-child(2) {
                                    transform: scaleX(0);
                                }
                                &:nth-child(3) {
                                    transform: translate3d(4px,1px,0) rotateZ(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .header-content {
                justify-content: space-between;
                align-items: center;
                .header--desktop-search-toggle {
                    display: block;
                    flex: 0 0 auto;
                    button {
                        .fa-search {
                            display: inline-block;
                        }
                        .fa-times {
                            display: none;
                        }
                        &[aria-expanded="true"] {
                            .fa-search {
                                display: none;
                            }
                            .fa-times {
                                display: inline-block;
                            }
                        }
                    }
                }
                .header--mobile-menu-toggle {
                    display: none;
                }
            }
        }
    }
}

