h1, h2, h3, .h1, .h2, .h3 {
    font-family: $major-headings-font-family;
    font-weight: $major-headings-font-weight;
    line-height: $major-headings-line-height;
    text-transform: uppercase;
}
h4, h5, h6, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    text-transform: none;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--theme--color--emphasis);
    margin-bottom: max(1rem, 0.5em);
    &:before {
        content: '';
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    strong, b {
        font-family: $font-heading-strong;
    }
}
h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    @include non-retina-font-smoothing-antialiased;
}

.heading-font {
    font-family: $headings-font-family;
    strong, b {
        font-family: $headings-font-family;
    }
}
.major-heading-font {
    font-family: $major-headings-font-family;
    strong, b {
        font-family: $major-headings-font-family;
    }
}

strong, b, .lead {
    font-family: $font-strong;
    font-weight: 700;
    @include non-retina-font-smoothing-antialiased;
}

.theme-icon {
    color: var(--theme--decor--color);
}

.custom-icon {
    display: inline-block;
    font-size: 2rem;
    width: 1em;
    height: 1em;
    vertical-align: -0.3em;
    .icon-primary {
        fill: var(--theme--custom-icon--primary);
    }
    .icon-secondary {
        fill: var(--theme--custom-icon--secondary);
    }
    .icon-tertiary {
        fill: var(--theme--custom-icon--tertiary);
    }
}

.muted {
    color: var(--theme--color--subtle);
}

.text-white {
    color: white !important;
}

.fa-ticket {
    transform: rotate(-30deg);
}

.instagram-logo {
    --height: 1.1em;
    display: inline-block;
    width: calc(var(--height) * 3.525);
    height: var(--height);
    background: url('../../img/instagram-logo--white.svg') no-repeat;
    background-size: 100%;
    vertical-align: middle;
    transform: translateY(2%);
}

.tripadvisor-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.number-roundel {
    position: relative;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.2em;
    height: 1.2em;
    color: var(--theme--background-color);
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    font-size: 0.8em;
    vertical-align: baseline;
    transform: translateY(-0.15em);
    @include non-retina-font-smoothing-antialiased;
    &:before {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--theme--decor--color);
        transform: translateY(-0.08em);
    }
}

#cookie-consent p {
    margin-bottom: 0;
}
