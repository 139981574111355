.promo-bar {
    pointer-events: none;
    .promo-bar--inner {
        mask-mode: alpha;
        mask-image: url('../../img/masks/promo-bar-mask-top.svg'), 
                    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII="),
                    url('../../img/masks/rough-bottom-edge-mask.svg');
        mask-position: center 0, 0 9px, 0 100%;
        mask-size: 800px 10px, 100% calc(100% - 18px), 900px 10px;
        mask-repeat: repeat-x, no-repeat, repeat-x;
        padding-top: 5px;
        padding-bottom: 5px;
        ul {
            width: 100%;
            min-height: 40px;
            pointer-events: all;
            li {
                font-size: 18px;
                font-family: $font-strong;
                font-weight: 700;
                @include non-retina-font-smoothing-antialiased;
                text-transform: uppercase;
            }
        }
    }
    &.header--promo-bar {
        position: absolute;
        z-index: 3;
        top: calc((var(--top-utility-bar--height) + var(--header--height)) - 10px);
        left: 0;
        width: 100%;
        .promo-bar--inner {
            padding-top: 12px;
        }
    }
}
