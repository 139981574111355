// Visual listing

.visual-listing {
    li {
        --panel-mask-url: url('../../img/masks/listing-item-mask-6.svg');
        --title-mask-url: url('../../img/masks/listing-item-title-mask-1.svg');
        .visual-listing--item {
            display: block;
            position: relative;
            margin: 0 auto;
            max-width: 460px;
            &:before {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 120%; // 6:5 ratio
            }
            .visual-listing--item--image, .visual-listing--item--content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .visual-listing--item--image {
                top: 20px;
                height: calc(100% - 20px);
                z-index: 1;
                mask-image: var(--panel-mask-url);
                mask-position: 0 0;
                mask-size: 100% 100%;
                mask-repeat: no-repeat;
                will-change: transform;
                overflow: visible !important;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .visual-listing--item--content {
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .visual-listing--item--content--upper {
                    h3 {
                        min-width: 270px;
                        width: fit-content;
                        margin: 0 auto;
                        padding: 1.5rem 12%;
                        text-align: center;
                        background-color: var(--theme--color--emphasis);
                        color: var(--theme--color--emphasis-inverted);
                        mask-image: var(--title-mask-url);
                        mask-position: 0 0;
                        mask-size: 100% 100%;
                        mask-repeat: no-repeat;
                        will-change: transform;
                    }
                }
            }

            // signpost mode
            &.visual-listing--signpost {
                &:hover, &:focus {
                    h3 {
                        text-decoration: underline;
                    }
                }
            }

            // cta mode
            &.visual-listing--ctas {
                .visual-listing--item--content--lower {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: center;
                    align-items: center;
                    p {
                        display: inline-block;
                        margin: 0;
                    }
                }
            }

            // panel mode
            &.visual-listing--panel {
                .visual-listing--item--content--lower {
                    padding: 0 var(--bs-gutter-x) var(--bs-gutter-x);
                    .custom-panel {
                        line-height: 1.1;
                    }
                }
            }

        }
        // image mask variations
        &:nth-child(6n-5)  {
            --panel-mask-url: url('../../img/masks/listing-item-mask-1.svg');
        }
        &:nth-child(6n-4)  {
            --panel-mask-url: url('../../img/masks/listing-item-mask-2.svg');
        }
        &:nth-child(6n-3)  {
            --panel-mask-url: url('../../img/masks/listing-item-mask-3.svg');
        }
        &:nth-child(6n-2)  {
            --panel-mask-url: url('../../img/masks/listing-item-mask-4.svg');
        }
        &:nth-child(6n-1) {
            --panel-mask-url: url('../../img/masks/listing-item-mask-5.svg');
        }
        // title mask variations
        &:nth-child(2n) {
            --title-mask-url: url('../../img/masks/listing-item-title-mask-2.svg');
        }
    }
}

// Custom panel listing

.custom-panel-listing {
    li {
        .custom-panel {
            display: block;
            position: relative;
            margin: 0 auto;
            max-width: 460px;
        }
        // image mask variations
        &:nth-child(4n-3) .custom-panel {
            --image-mask-url: url('../../img/masks/custom-panel-image-mask-1.svg');
        }
        &:nth-child(4n-2) .custom-panel {
            --image-mask-url: url('../../img/masks/custom-panel-image-mask-2.svg');
        }
        &:nth-child(4n-1) .custom-panel {
            --image-mask-url: url('../../img/masks/custom-panel-image-mask-3.svg');
        }
    }
}


// shortcuts listing

ul.custom-icon-listing {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    gap: 15px;
    li {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        padding: 0 .75rem 0 0; // balance the scaling icon
        margin: 0;
        a {
            display: flex;
            align-items: center;
            & > span {
                display: block;
            }
            svg {
                font-size: 3.5rem;
                transform: scale(0.8);
                will-change: transform;
                transition: transform 300ms ease-in-out;
            }
            & > div {
                text-align: left;
                h2 {
                    display: inline-block;
                    color: var(--theme--link--color);
                }
                p {
                    color: var(--theme--color);
                }
            }
            &:hover, &:focus {
                text-decoration: none;
                svg {
                    transform: scale(1);
                    transition-timing-function: ease-out;
                }
                & > div {
                    h2 {
                        color: var(--theme--link--color--hover);
                    }
                    p {
                        color: var(--theme--color);
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        li {
            width: auto;
        }
    }
}
