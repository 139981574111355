// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$dark};
    color: var(--theme--color);
    --theme--color--alt: #{$brand--orange};
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--magenta};
    --theme--color--emphasis-inverted: #{$brand--white}; // when emphasis is used for bg-color
    // for minor text, helper text etc
    --theme--color--subtle: #{lighten($dark, 20%)};
    // links
    --theme--link--color: #{$link-color};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$link-hover-color};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$primary};
    --theme--rule--color: #{$brand--light-grey};
    --theme--custom-icon--primary: #{$brand--dark-grey};
    --theme--custom-icon--secondary: #{$brand--magenta};
    --theme--custom-icon--tertiary: #{$brand--white};
    // button - standard
    --theme--btn--bg-color: #{$link-color};
    --theme--btn--border-color: #{$link-color};
    --theme--btn--color:  #{$brand--white};
    --theme--btn--bg-color--hover: #{$link-hover-color};
    --theme--btn--border-color--hover: #{$link-hover-color};
    --theme--btn--color--hover:  #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$primary};
    --theme--btn--primary--border-color: #{$primary};
    --theme--btn--primary--color:  #{$brand--white};
    --theme--btn--primary--bg-color--hover: #{$brand--magenta};
    --theme--btn--primary--border-color--hover: #{$brand--magenta};
    --theme--btn--primary--color--hover:  #{$brand--white};
    // button - secondary
    --theme--btn--secondary--bg-color: #{$secondary};
    --theme--btn--secondary--border-color: #{$secondary};
    --theme--btn--secondary--color:  #{$brand--white};
    --theme--btn--secondary--bg-color--hover: #{$brand--magenta};
    --theme--btn--secondary--border-color--hover: #{$brand--magenta};
    --theme--btn--secondary--color--hover:  #{$brand--white};
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$light};
    color: var(--theme--color);
    --theme--color--alt: #{$brand--yellow};
    // for headings, quotes etc
    --theme--color--emphasis:  #{$brand--white};
    --theme--color--emphasis-inverted: var(--theme--background-color); // when emphasis is used for bg-color
    // for minor text, helper text etc
    --theme--color--subtle: #{darken($light, 10%)};
    // links
    --theme--link--color: #{$brand--white};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$brand--white};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--white};
    --theme--rule--color: #{$brand--dark-grey};
    --theme--custom-icon--primary: #{$brand--white};
    --theme--custom-icon--secondary: #{$brand--yellow};
    --theme--custom-icon--tertiary: #{$brand--dark-grey};
    // button - standard
    --theme--btn--bg-color: #{$link-color};
    --theme--btn--border-color: #{$link-color};
    --theme--btn--color:  #{$brand--white};
    --theme--btn--bg-color--hover: #{$link-hover-color};
    --theme--btn--border-color--hover: #{$link-hover-color};
    --theme--btn--color--hover:  #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--white};
    --theme--btn--primary--border-color: #{$brand--white};
    --theme--btn--primary--color:  #{$brand--magenta};
    --theme--btn--primary--bg-color--hover: #{$brand--white};
    --theme--btn--primary--border-color--hover: #{$brand--white};
    --theme--btn--primary--color--hover:  #{$dark};
    // button - secondary
    --theme--btn--secondary--bg-color: #{$secondary};
    --theme--btn--secondary--border-color: #{$secondary};
    --theme--btn--secondary--color:  #{$brand--white};
    --theme--btn--secondary--bg-color--hover: #{$link-hover-color};
    --theme--btn--secondary--border-color--hover: #{$link-hover-color};
    --theme--btn--secondary--color--hover:  #{$brand--white};
}


// light themes

.bg--theme--white, .bg--theme--light-grey, .bg--theme--lightest-grey, .bg--theme--light-grey--vertical-gradient, .bg--theme--light-blue, .bg--theme--yellow, .bg--theme--lightest-yellow, .bg--theme--translucent-white {
    @include on-light-theme-vars;
}
.bg--theme--white {
    --theme--background-color: #{$brand--white};
    --theme--background: #{$brand--white};
    background: var(--theme--background);
}
.bg--theme--light-grey {
    --theme--background-color: #{$brand--light-grey};
    --theme--background: #{$brand--light-grey};
    background: var(--theme--background);
}
.bg--theme--light-grey--vertical-gradient {
    --theme--background-color: #{$brand--light-grey};
    --theme--background: linear-gradient(to bottom, #{$brand--light-grey} 5%, #{$brand--white} 100%);
    background: var(--theme--background);
}
.bg--theme--lightest-grey {
    --theme--background-color: #{$brand--lightest-grey};
    --theme--background: #{$brand--lightest-grey};
    background: var(--theme--background);
}
.bg--theme--light-blue {
    --theme--background-color: #{$brand--light-blue};
    --theme--background: #{$brand--light-blue};
    background: var(--theme--background);
}
.bg--theme--yellow {
    --theme--background-color: #{$brand--yellow};
    --theme--background: #{$brand--yellow};
    background: var(--theme--background);
}
.bg--theme--lightest-yellow {
    --theme--background-color: #{$brand--lightest-yellow};
    --theme--background: #{$brand--lightest-yellow};
    background: var(--theme--background);
}
.bg--theme--translucent-white {
    --theme--background-color: #{$brand--white};
    --theme--background: #{rgba(white, 0.7)};
    background: var(--theme--background) !important;
    backdrop-filter: blur(10px);
}

// dark themes

.bg--theme--magenta, .bg--theme--magenta-gradient, .bg--theme--orange, .bg--theme--dark-blue-gradient {
    @include on-dark-theme-vars;
}
.bg--theme--magenta {
    --theme--background-color: #{$brand--magenta};
    --theme--background: #{$brand--magenta};
    --theme--btn--primary--bg-color: #{$brand--orange};
    --theme--btn--primary--border-color: #{$brand--orange};
    --theme--btn--primary--color:  #{$brand--white};
    background: var(--theme--background);
}
.bg--theme--magenta-gradient {
    --theme--background-color: #{$brand--dark-magenta};
    --theme--background: linear-gradient(to right, #{$brand--dark-magenta} 5%, #{$brand--magenta} 95%);
    --theme--btn--primary--bg-color: #{$brand--orange};
    --theme--btn--primary--border-color: #{$brand--orange};
    --theme--btn--primary--color:  #{$brand--white};
    background: var(--theme--background);
}
.bg--theme--orange {
    --theme--background-color: #{$brand--orange};
    --theme--background: #{$brand--orange};
    --theme--btn--primary--bg-color: #{$brand--magenta};
    --theme--btn--primary--border-color: #{$brand--magenta};
    --theme--btn--primary--color:  #{$brand--white};
    --theme--custom-icon--primary: #{$brand--dark-grey} !important;
    --theme--custom-icon--secondary: #{$brand--magenta} !important;
    --theme--custom-icon--tertiary: #{$brand--white} !important;
    background: var(--theme--background);
}
.bg--theme--dark-blue-gradient {
    --theme--background-color: #{$brand--darkest-blue};
    --theme--background: radial-gradient(circle at center, #{$brand--dark-blue}, #{$brand--darker-blue}, #{$brand--darkest-blue});
    --theme--btn--primary--bg-color: #{$brand--orange};
    --theme--btn--primary--border-color: #{$brand--orange};
    --theme--btn--primary--color:  #{$brand--white};
    background: var(--theme--background);
}

// custom backgrounds

.bg--transparent {
    background-color: transparent !important;
}

