@keyframes video-loading-sprite {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

.hero {
    position: relative;
    z-index: 1;
    height: 100vw; // square
    max-height: 600px;
    will-change: transform;
    @include media-breakpoint-up(md) {
        height: calc(.75 * 100vw); // 4:3
    }

    @include media-breakpoint-up(lg) {
        height: calc(.5625 * 100vw); // 16:9
    }

    @include media-breakpoint-up(xl) {
        height: calc(50vw); // 2:1
    }

    @media(min-width: 1600px) {
        height: calc(100vw / 3); // 3:1
    }

    // set a min height for small, landscape screens
    @media (max-width: map-get($grid-breakpoints, lg)) and (min-aspect-ratio: 4/3) {
        min-height: $hero--landscape--min-height;
    }

    .site-layout & {
        margin-top: calc(-1 * var(--header--height));
    }

    // non-image shallow hero
    &.hero--non-image {
        height: calc(var(--header--height) + 150px);
        background: var(--theme--background-color) url('../../img/hero-texture.svg') top left no-repeat !important;
        background-size: auto 100%;
        @include media-breakpoint-up(md) {
            background-size: 100% 100%;
        }
    }

    // hero layout using flex to stretch images and content to fit vertically
    .hero--layout {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        .hero--img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: $brand--dark-grey;
            img {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
        }
        .hero--content {
            display: block;
            position: relative;
            z-index: 2;
            width: 100%;
        }
    }

    // brush mask for image area
    &.bottom-mask--brush.hero--non-image, 
    &.bottom-mask--brush .hero--layout .hero--img, 
    &.bottom-mask--brush .hero--layout .hero--video {
        --bottom-mask--src: url('../../img/masks/hero-brush-mask.svg');
        --bottom-mask--size-x: max(800px,100%);
        --bottom-mask--size-y: 100px;
        --bottom-mask--position-x: center;
        --bottom-mask--position-y: bottom;
        --bottom-mask--repeat-x: no-repeat;
        mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=), var(--bottom-mask--src);
        mask-position: left top, var(--bottom-mask--position-x) var(--bottom-mask--position-y);
        mask-size: 100% calc(100% - (var(--bottom-mask--size-y) - 1px)), var(--bottom-mask--size-x) var(--bottom-mask--size-y);
        mask-repeat: no-repeat, var(--bottom-mask--repeat-x);
        will-change: transform;
    }

    // use '.full-height' to fill the available viewport space

    &.full-height {
        min-height: $hero--mobile--min-height;
        max-height: none;
        height: calc(100vh - var(--top-utility-bar--height));
        @include media-breakpoint-up(sm) {
            min-height: $hero--desktop--min-height;
        }
    }

    // hero carousel

    .carousel, .carousel-inner, .carousel-item {
        height: 100%;
    }
}


// Hero video stuff

.hero .hero--layout .hero--video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.vimeo > iframe { // bug fix for vimeo embed
        display: none;
    }
    .hero--video-inner { // should remain as 16:9 ratio container and resize to emulate object-fit: cover
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        --transition: opacity 1s linear;
        .hero--poster-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            opacity: 1;
            transition: var(--transition);
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition: opacity 500ms linear;
                &.loaded {
                    opacity: 1;
                }
            }
        }
        iframe {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: var(--transition);
        }
        &.reveal {
            .hero--poster-image {
                opacity: 0;
            }
            iframe {
                opacity: 1;
            }
        }
        &.has-poster {
            --transition: none;
            iframe {
                opacity: 1;
            }
            &.crossfade-poster {
                --transition: opacity 1s linear;
            }
        }
    }
    .hero--video-loading-sprite {
        position: absolute;
        z-index: 4;
        top: calc(var(--header--height) + 75px);
        left: 15px;
        width: 60px;
        height: 3px;
        overflow: hidden;
        opacity: 1;
        will-change: opacity;
        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: calc(100% + 30px);
            height: 100%;
            background: linear-gradient(to right, rgba(black, 0.4) 0%, rgba(black, 0.4) 50%, white 50%, white 100%) repeat-x;
            background-size: 20px 100%;
            will-change: transform;
            animation: video-loading-sprite 1s infinite linear;
        }
        &.hidden {
            opacity: 0;
            transition: opacity 500ms linear;
        }
    }
    .hero--video--control {
        display: flex;
        flex-direction: row;
        gap: 4px;
        position: absolute;
        z-index: 4;
        top: calc(var(--header--height) + 75px);
        right: 15px;
        opacity: 0;
        transition: opacity 500ms linear;
        pointer-events: none;
        &.reveal {
            opacity: 1;
            pointer-events: all;
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            appearance: none;
            width: 30px;
            height: 30px;
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            padding: 0;
            border: 0;
            border-radius: 3px;
            background-color: rgba(black, .4);
            color: white;
            cursor: pointer;
            &:not(:disabled):focus, &:not(:disabled):hover {
                background-color: $primary;
                color: white;
            }
            .hero--video--control--play, .hero--video--control--pause {
                display: inline;
                &[aria-hidden="true"] {
                    display: none;
                }
            }
        }
    }
}
