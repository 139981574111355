a, .btn, button {
    svg.fa-arrow-right, svg.fa-arrow-left {
        will-change: transform;
        transition: transform 200ms ease-in-out;
    }
    svg.fa-arrow-right {
        margin-left: 0.5em;
    }
    svg.fa-arrow-left {
        margin-right: 0.5em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg.fa-arrow-right {
                transform: translate3d(0.3em,0,0);
            }
            svg.fa-arrow-left {
                transform: translate3d(-0.3em,0,0);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn:not(.btn-theme) {
    &.btn-secondary:not(.btn-link):not(.btn-outline) {
        color: $white;
    }
    &.btn-link {
        background-color: transparent;
        border-color: transparent;
    }
}

.btn-theme {
    position: relative;
    z-index: 2;
    font-family: $headings-font-family;
    font-weight: 900;
    line-height: $headings-line-height;
    text-transform: uppercase;
    @include non-retina-font-smoothing-antialiased;
    color: var(--theme--link--color);
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus, a:hover &, a:focus & {
            color: var(--theme--link--color--hover);
        }
    }
    &:not(.btn-link) {
        background-color: var(--theme--btn--bg-color);
        border-color: var(--theme--btn--border-color);
        color: var(--theme--btn--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus, a:hover &, a:focus & {
                background-color: var(--theme--btn--bg-color--hover);
                border-color: var(--theme--btn--border-color--hover);
                color: var(--theme--btn--color--hover);
            }
        }
        &.btn-primary {
            background-color: var(--theme--btn--primary--bg-color);
            border-color: var(--theme--btn--primary--border-color);
            color: var(--theme--btn--primary--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    border-color: var(--theme--btn--primary--border-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                }
            }
        }
        &.btn-secondary {
            background-color: var(--theme--btn--secondary--bg-color);
            border-color: var(--theme--btn--secondary--border-color);
            color: var(--theme--btn--secondary--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--secondary--bg-color--hover);
                    border-color: var(--theme--btn--secondary--border-color--hover);
                    color: var(--theme--btn--secondary--color--hover);
                }
            }
        }
        &.btn-outline, &.btn-outline-secondary {
            border-color: var(--theme--btn--border-color);
            background-color: transparent;
            color: var(--theme--btn--bg-color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--bg-color--hover);
                    border-color: var(--theme--btn--border-color--hover);
                    color: var(--theme--btn--color--hover);
                }
            }
        }
        &.btn-outline-primary {
            background-color: transparent;
            border-color: var(--theme--btn--primary--border-color);
            color: var(--theme--btn--primary--bg-color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    border-color: var(--theme--btn--primary--border-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                }
            }
        }
    }
    &:not(.btn-outline):not(.btn-outline-primary):not(.btn-outline-secondary) {
        mask-image: url('../../img/masks/button-mask.svg');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        padding: calc(#{$btn-padding-y} + 0.5em) calc(#{$btn-padding-x} + 0.5em);padding: 0.75rem 1.5rem;
        font-size: max(16px, 0.875rem);
        &.btn-lg {
            font-size: max(16px, 0.875rem);
            padding: calc(#{$btn-padding-y-lg} + 0.75em) calc(#{$btn-padding-x-lg} + 1em);
        }
        &.btn-sm {
            font-size: max(15px, 0.875rem);
            padding: calc(#{$btn-padding-y-sm} + 0.4em) calc(#{$btn-padding-x-sm} + 0.75em);
        }
    }
    &.btn-link {
        background-color: transparent;
        border-color: transparent;
        &.btn-primary {
            color: var(--theme--link--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    color: var(--theme--link--color--hover);
                }
            }
        }
    }
}
